import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AuftragsarbeitenPage = () => (
  <Layout>
    <SEO title="Auftragsarbeiten" />

    <h1 id="ueberschrift">Informationen zum Produktionsangebot</h1>
    <Link to="/fdml_3a_auftragsarbeiten-klartext#ueberschrift" title="Detaillierte Informationen zum Produktionsangebot" style={{fontSize: "1.5em"}}>zum Klartext</Link>

    <p className="poem" style={{marginTop: "0.5em"}}>
      Die Wand zu schräg, der Raum zu klein,<br />
      Ein Schmuckkästchen, das soll es sein?<br />
      Ein Kleid zu Fasching, der Rock zu lang,<br />
      Der Pulli sitzt schon viel zu stramm?<br />
      Ein Schal / ein Tier aus Häkelgarn,<br />
      Ein Puzzle treibt Dich in den Wahn?<br />
      `ne tolle Karte wäre gut,<br />
      Das Basteln liegt Dir nicht im Blut?<br />
      Ein neues T-Shirt oder Tuch<br />
      Mit einem ganz besond‘rem Spruch?
    </p>
    <p className="poem" style={{marginTop: "0.5em"}}>
      So viele ganz besond‘re Sachen…<br />
      …mit Fantasie sind sie zu machen.<br />
      Mit einem zwanglosen Geplausch,<br />
      Kommt erstmal der Gedankentausch.<br />
      Und wenn dann klar ist, was Du willst,<br />
      Du Dir vielleicht den Wunsch erfüllst.
    </p>
  </Layout>
)

export default AuftragsarbeitenPage
